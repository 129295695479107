@charset 'UTF-8';
@import 'mixin';

//image/パス
$imgPath:'../../images/';

//プログラム説明会ボタンがfixedになったため、body下に余白を作る
#index{
	@include sp{
		padding-bottom:120px;
	}
}

.top_contents{
	display:block;
	//padding:100px 0 60px;
	padding:0 0 60px;
	background:$color-green;
	@include sp{
		//padding:56px 0 80px;
	}
}

.top_hero{
	position:relative;
	width:100%;
	min-width:1000px;
	min-height:480px;
  background-color: #FFFFFF;
	background-image: url($imgPath + 'top-hero@2x.png');
  background-position: calc( 50% + 40px ) center;
  background-repeat: no-repeat;
	background-size:contain;
	@include sp{
		min-width:0;
    min-height: auto;
    margin-bottom: 136px;
		background-image:url($imgPath + 'top-hero-sp.png');
    background-position:center center;
    background-size: cover;
	}
	@include sp5{
    margin-bottom: 116px;
	}
	&--logo{
		&-img{
			position:absolute;
			display:block;
      top: 65px;
      left: 50%;
      transform: translateX(-469px);
			@include sp{
        width: 280px;
        top: auto;
        bottom: -104px;
        left: 24px;
        transform: translateX(0);
			}
			@include sp5{
        width: 239px;
        bottom: -84px;
			}
		}
	}
	&--middle_challenge{
		position:absolute;
		left: calc(50% - 660px); // For IE
		left: max(calc(50% - 660px), 26px);
		top:24px;
		width:151px;
		height:28px;
		@include sp {
			display: none;
		}
	}
  &--payment {
    position: absolute;
    bottom: -53px;
    left: 50%;
    transform: translateX(278px);
    @include sp {
      width: 128px;
      bottom: 39px;
      left: auto;
      right: 20px;
      transform: translateX(0);
    }
    @include sp5 {
      width: 109px;
    }
  }
}

.top{
	&--section{
		color:#fff;
		padding:60px 0;
		text-align:center;
		@include sp{
			width:100%;
			padding:50px 32px;
		}
	}
	&--ttl{
		position:relative;
		color:$color-yellow;
		font-size:40px;
		line-height:1.5;
		font-weight:900;
		margin:0 0 90px;
		@include sp{
			font-size:30px;
			margin-bottom:72px;
		}
		&:after{
			content:'';
			position:absolute;
			left:50%;
			bottom:-32px;
			width:64px;
			height:2px;
			background:$color-orange;
			margin-left:-32px;
			@include sp{
				bottom:-30px;
			}
		}
		&-no_border {
			margin-bottom: 32px;
			@include sp {
				margin-bottom: 20px;
			}
		}
		&-no_border:after {
			display: none;
		}
	}
	&--btn{
		display:flex;
		flex-direction:column;
		justify-content:center;
		align-items:center;
		color:#fff;
		font-size:22px;
		font-weight:900;
		border:2px solid #fff;
		width:350px;
		height:84px;
		border-radius:42px;
		margin:0 auto;
		transition:.15s linear;
		@include sp{
			font-size:16px;
			width:auto;
			height:64px;
			border-radius:32px;
		}
		& span{
			display:block;
			font-size:14px;
			font-weight:500;
			margin:9px 0 0;
			@include sp{
				font-size:11px;
			}
		}
		&:hover{
			background:#fff;
			border-color: $color-green;
			color: $color-green;
		}
		&-orange{
			background:$color-orange;
			border-width:1px;
			box-shadow:0 3px 6px rgba(0,0,0,.16);
			& span{
				font-weight:900;
				margin:0 0 9px;
			}
			&:hover{
				background-color:#fff;
				color: $color-orange;
				border-color: $color-orange;
			}
		}
		&-green{
			color:$color-green;
			border-color:$color-green;
			&:hover{
				color:#fff;
				background:$color-green;
			}
		}
		&-program{
			margin:32px auto 56px;
			@include sp{
				//margin:32px 32px 40px;
				width:calc(100% - 132px);
				margin:0 32px 0 100px;
				position:fixed;
				bottom:40px;
				z-index:99;
        opacity: 0;
        transition: 0.15s;
        &.js-fixed {
          opacity: 1;
        }
			}
			@include sp5{
				bottom:16px;
			}
		}
	}
  &--btn_schedule {
    display:flex;
    position: fixed;
    left: 0;
    bottom: 40px;
    padding: 4px 8px 8px 12px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: $fw-black;
    color: $color-green;
    line-height: 1.4;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 0 22px 22px 0;
    border: $color-green 3px solid;
    border-left: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: 0.15s;
    z-index: 99;
    &:after {
      display: block;
      content: '';
      width: 32px;
      height: 32px;
      margin-top: 6px;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2231.89%22%20height%3D%2231.889%22%3E%3Cpath%20data-name%3D%22Icon%20ionic-md-calendar%22%20d%3D%22M25.246%2017.273h-7.972v7.972h7.972ZM22.588%200v2.657H9.3V0H5.315v2.657H3.322A3.331%203.331%200%200%200%200%205.979v22.588a3.331%203.331%200%200%200%203.322%203.322h25.246a3.331%203.331%200%200%200%203.322-3.322V5.979a3.331%203.331%200%200%200-3.322-3.322h-1.994V0Zm5.979%2028.567H3.322V10.962h25.246Z%22%20fill%3D%22%232b8d4e%22%2F%3E%3C%2Fsvg%3E');
    }
    &:hover {
      padding-left: 48px;
    }
    @include sp {
      padding: 3px 6px 7px 8px;
      font-size: 10px;
      transform: translateX(-100%);
      &.js-fixed {
        transform: translateX(0);
      }
      &:after {
        width: 22px;
        height: 22px;
        margin-top: 4px;
        background-size: contain;
      }
    }
    @include sp5 {
      bottom: 16px;
    }
  }
}

.top_btns {
  display: none;
  @include sp {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &--schedule {
    @include sp {
      display: inline-block;
      padding: 3px 6px 7px 8px;
      font-size: 10px;
      font-weight: $fw-black;
      color: $color-green;
      line-height: 1.4;
      text-align: center;
      background-color: #FFFFFF;
      border-radius: 0 22px 22px 0;
      border: $color-green 3px solid;
      border-left: none;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      transition: 0.15s;
      &:after {
        display: block;
        content: '';
        width: 22px;
        height: 22px;
        margin: 4px auto 0;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2231.89%22%20height%3D%2231.889%22%3E%3Cpath%20data-name%3D%22Icon%20ionic-md-calendar%22%20d%3D%22M25.246%2017.273h-7.972v7.972h7.972ZM22.588%200v2.657H9.3V0H5.315v2.657H3.322A3.331%203.331%200%200%200%200%205.979v22.588a3.331%203.331%200%200%200%203.322%203.322h25.246a3.331%203.331%200%200%200%203.322-3.322V5.979a3.331%203.331%200%200%200-3.322-3.322h-1.994V0Zm5.979%2028.567H3.322V10.962h25.246Z%22%20fill%3D%22%232b8d4e%22%2F%3E%3C%2Fsvg%3E');
        background-size: contain;
      }
    }
  }
  &--program {
    @include sp{
      width:calc(100% - 132px);
      margin: 0 32px 0 0;
      position: static;
    }
  }
}

.top_topics{
	color:#fff;
	width:680px;
	border:1px solid #fff;
	border-radius:5px;
	padding:24px;
	margin:0 auto 60px;
	@include sp{
		width:calc(100% - 64px);
		//margin:0 32px 40px;
		margin: 36px 32px 40px;
		padding:16px;
	}
	&--heading{
		font-size:16px;
		font-weight:500;
		margin-bottom:12px;
		@include sp{
			font-size:12px;
		}
	}
	&--heading_ttl{
		display:inline-block;
		color:$color-green;
		font-size:12px;
		font-weight:500;
		background:#fff;
		border-radius:3px;
		padding:4px 8px;
		margin-right: 8px;
		@include sp{
			font-size:10px;
		}
	}
	&--data{
		font-size:14px;
		font-weight:500;
		line-height:1.5;
		font-weight:500;
		margin-bottom: 12px;
		&:last-child {
			margin-bottom: 0;
		}
		@include sp{
			font-size:12px;
		}
	}
	&--data_link{
		font-size:inherit;
		font-weight:inherit;
		display:block;
		&:hover{text-decoration:underline;}
	}
}

.top_intro{
	&--ttl{
		text-shadow:0 3px 6px rgba(0,0,0,.16);
		&:after{display:none;}
		margin-bottom:56px;
		@include sp{
			margin-bottom:30px;
		}
	}
	&--txt{
		font-size:18px;
		line-height:1.77;
		font-weight:900;
		margin-bottom:24px;
		@include sp{
			font-size:14px;
			line-height:2;
			margin:0 -10px 16px;
		}
	}
	&--em{
		font-style:normal;
		font-weight:inherit;
		color:$color-yellow;
	}
	&--caption{
		font-size:14px;
		font-weight:500;
		@include sp{
			font-size:12px;
		}
	}
	&--btn_faq{
		display:flex;
		justify-content:flex-start;
		align-items:center;
		width:350px;
		height:100px;
		background:#fff;
		border-radius:8px;
		box-shadow:0 3px 6px rgba(0,0,0,.16);
		margin:40px auto;
		transition:.15s linear;
		@include sp{
			display:none;
		}
		&-div{
			color:$color-green;
			font-size:25px;
			font-weight:900;
			text-align:left;
		}
		&-icon{
			width:58px;
			height:58px;
			margin:0 20px 0 26px;
		}
		&-span{
			display:block;
			font-size:17px;
			color:$color-text;
			font-weight:inherit;
			margin-bottom:6px;
		}
		&:hover{
			background:rgba(255,255,255,.8);
		}
	}
}

.top_guide{
	&--list{
		display:flex;
		justify-content:center;
		align-items:flex-start;
		@include sp{
			display:block;
			width:calc(100% + 64px);
			margin:0 -32px;
		}
	}
	&--item{
		text-align:center;
		width:480px;
		margin:0 20px;
		@include sp{
			width:100%;
			margin:0 0 48px;
			&:last-of-type{margin:0;}
		}
	}
	&--photo{
		border-radius:5px;
		margin:0 0 24px;
		@include sp{
			width:100%;
			height:184px;
			object-fit:cover;
			border-radius:0;
		}
		@include sp5{
			height:176px;
		}
	}
	&--ttl{
		color:$color-yellow;
		font-size:24px;
		font-weight:900;
		margin:0 0 28px;
		text-shadow:0 3px 6px rgba(0,0,0,.16);
		@include sp{
			font-size:20px;
			margin-bottom:10px;
		}
	}
	&--txt{
		color:#fff;
		font-size:14px;
		line-height:1.5;
		font-weight:900;
		margin:0 0 32px;
		text-shadow:0 3px 6px rgba(0,0,0,.16);
		@include sp{
			font-size:12px;
			margin-bottom:24px;
		}
	}
	&--btn{
		font-size:16px;
		font-weight:900;
		display:flex;
		justify-content:center;
		align-items:center;
		width:226px;
		height:56px;
		border:1px solid #fff;
		border-radius:28px;
		margin:0 auto;
		transition:.15s linear;
		&:hover{
			background:#fff;
			border-color: $color-green;
			color: $color-green;
		}
		@include sp{
			font-size:14px;
			width:177px;
			height:42px;
		}
	}
}

.top_point{
	width:792px;
	margin:60px auto;
	padding:48px;
	background:#fff;
	border-radius:16px;
	@include sp{
		width:calc(100% - 48px);
		margin:50px 24px 24px;
		padding:32px 24px;
		border-radius:8px;
	}
	&--ttl{
		margin:0 auto 50px;
		@include sp{
			width:222px;
			height:85px;
			margin-bottom:32px;
		}
	}
	&--list{
		display:flex;
		justify-content:space-between;
		align-items:stretch;
		margin:0 0 32px;
		@include sp{
			display:block;
			margin-bottom:0;
		}
	}
	&--item{
		width:328px;
		@include sp{
			width:100%;
			margin-bottom:24px;
		}
	}
	&--sub{
		display:flex;
		align-items:center;
		justify-content:center;
		width:100%;
		height:73px;
		border-radius:5px;
		color:#fff;
		font-size:20px;
		font-weight:900;
		line-height:1.4;
		background:$color-orange;
		margin:0 0 20px;
		@include sp{
			font-size:16px;
			height:auto;
			padding:9px 0;
			margin:0 0 16px;
		}
	}
	&--txt{
		color:$color-text;
		font-size:14px;
		line-height:1.5;
		font-weight:500;
		text-align:justify;
		line-break:strict;
		padding:0 7px;
		@include sp{
			padding:0 6px;
		}
	}
	&--btn_faq{
		display:none;
		@include sp{
			display:flex;
			justify-content:flex-start;
			align-items:center;
			width:295px;
			height:79px;
			background:#fff;
			border-radius:8px;
			box-shadow:0 3px 6px rgba(0,0,0,.16);
			margin:32px auto 50px;
			transition:.15s linear;
			&-div{
				color:$color-green;
				font-size:20px;
				font-weight:900;
				text-align:left;
			}
			&-icon{
				width:50px;
				height:50px;
				margin:0 20px 0 26px;
			}
			&-span{
				display:block;
				font-size:14px;
				color:$color-text;
				font-weight:inherit;
				margin-bottom:6px;
			}
			&:hover{
				background:rgba(255,255,255,.8);
			}
		}
	}
}
.top_point2 {
  position: relative;
  max-width: 920px;;
  margin: 0 auto;
  border-radius: 16px;
  background-color: #f7f9e7;
  padding: 48px 40px;
  @include sp {
    margin-left: 24px;
    margin-right: 24px;
    padding-left: 30px;
    padding-right: 30px;
  }
  &--ttl {
  }
  &--ttl_img {
    display: block;
    margin: 0 auto;
    @include sp {
      max-width: 222px;
    }
  }
  &--img {
    position: absolute;
    right: 74px;
    bottom: 0;
    @include sp {
      display: none;
    }
  }
  &--btn {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 22px;
    font-weight: 900;
    border: 2px solid #fff;
    width: 350px;
    height: 84px;
    border-radius: 42px;
    margin: 0 auto;
    margin-top: 40px;
    transition: .15s linear;
    @include sp {
      margin-top: 32px;
      font-size: 16px;
      width: 263px;
      height: 64px;
      border-radius: 32px;
    }
    & span {
      display: block;
      font-size: 14px;
      font-weight: 500;
      margin: 9px 0 0;
      @include sp {
        font-size: 11px;
      }
    }
    &:hover {
      background: #fff;
      border-color: $color-green;
      color: $color-green;
    }
    &-green {
      color: $color-green;
      border-color: $color-green;
      &:hover {
        color: #fff;
        background: $color-green;
      }
    }
  }
}
.top_point2_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  margin-top: 40px;
  @include sp {
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
  &--item {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
  }
  &--content {
    padding: 5px 24px 24px;
  }
  &--img {
    display: block;
    margin: 0 auto;
    @include sp {
      width: 100%;
    }
  }
  &--point {
    font-family: 'Noto Sans JP', sans-serif;
    color: #2b8d4e;
    display: flex;
    column-gap: 5px;
    align-items: baseline;
  }
  &--point_txt {
    font-weight: 900;
    line-height: 1.5;
    font-size: 16px;
  }
  &--point_number {
    font-weight: 900;
    line-height: 1.5;
    font-size: 24px;
  }
  &--desc {
    color: #ff9035;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
  }
}
.top_intended{
	&--list{
		display:flex;
		align-items:center;
		justify-content:center;
		margin:0 0 40px;
		@include sp{
			display:block;
			margin-bottom:20px;
		}
	}
	&--item{
		display:flex;
		align-items:center;
		justify-content:center;
		width:306px;
		height:89px;
		color:$color-green;
		font-size:20px;
		line-height:1.4;
		font-weight:900;
		background:#fff;
		border-radius:5px;
		@include sp{
			width:100%;
			height:79px;
			font-size:16px;
		}
		&:first-of-type{
			position:relative;
			margin-right:88px;
			@include sp{
				margin:0 0 48px;
			}
			&:after{
				color:#fff;
				position:absolute;
				right:-88px;
				top:0;
				display:flex;
				align-items:center;
				justify-content:center;
				content:'+';
				font-size:48px;
				line-height:1;
				text-align:center;
				font-weight:600;
				width:88px;
				height:89px;
				@include sp{
					font-size:28px;
					right:auto;
					left:0;
					top:auto;
					bottom:-48px;
					width:100%;
					height:48px;
				}
			}
		}
	}
	&--txt{
		font-size:23px;
		line-height:1.5;
		font-weight:500;
		@include sp{
			font-size:16px;
		}
		&-strong{
			font-size:29px;
			font-weight:900;
			@include sp{
				font-size:20px;
			}
		}
	}
}

.top_merit{
	&--list{
		display:flex;
		align-items:stretch;
		justify-content:center;
		@include sp{
			flex-direction:column;
		}
	}
	&--item{
		width:237px;
		background:$color-cream;
		padding:24px;
		margin:0 8px;
		border-radius:8px;
		@include sp{
			width:100%;
			padding:16px;
			margin:0 0 13px;
		}
	}
	&--sub{
		line-height: 1.3;
		color:$color-green;
		font-size:20px;
		font-weight:900;
		text-align:center;
		margin:0 -10px 32px;
		@include sp{
			display:flex;
			align-items:center;
			font-size:18px;
			line-height:1;
			text-align:left;
			margin:0 0 12px;
		}
		&:before{
			display:block;
			@include icon;
			content:$icon-point01;
			color:$color-orange;
			font-size:48px;
			margin:0 0 22px;
			@include sp{
				font-size:40px;
				display:inline-block;
				margin:0 12px 0 0;
			}
		}
		&-02:before{content:$icon-point02;};
		&-03:before{content:$icon-point03;};
		&-04:before{content:$icon-point04;};
	}
	&--txt{
		color:$color-text;
		font-size:14px;
		font-weight:500;
		line-height:1.5;
		text-align:justify;
		line-break:strict;
		@include sp{
			font-size:12px;
		}
	}
	&--caption{
		color:$color-text;
		font-size:12px;
		font-weight:500;
		text-align:left;
		margin-top:1em;
		@include sp{
			font-size:10px;
			margin-top:0.5em;
		}
	}
}

.top_curriculum{
	&--wrap{
		display:flex;
		justify-content:center;
		align-items:flex-start;
		@include sp{
			display:block;
		}
	}
	&--photo{
		border-radius:5px;
		margin:0 40px 0 0;
		@include sp{
			margin:0;
		}
	}
	&--block{
		width:460px;
		text-align:justify;
		@include sp{
			width:100%;
			padding:24px 24px 0;
		}
	}
	&--txt{
		font-size:16px;
		font-weight:500;
		line-height:1.5;
		text-align:justify;
		margin-bottom:40px;
		@include sp{
			font-size:14px;
			text-align:center;
			margin-bottom:32px;
		}
	}
	&--btn{
		margin:0;
	}
}

.top_program{
  @include sp{
    padding-bottom:0;
  }
	&--txt{
		font-size:16px;
		font-weight:500;
		line-height:1.5;
		margin:0 0 40px;
		@include sp{
			font-size:14px;
		}
	}
	&--sub{
		font-size:22px;
		line-height:1.5;
		font-weight:900;
		margin:0 0 32px;
		@include sp{
			font-size:16px;
		}
	}
	&--list{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		width:808px;
		margin:0 auto 32px;
		@include sp{
			width:100%;
			margin:0 0 18px;
		}
	}
	&--item{
		color:$color-text;
		font-size:16px;
		font-weight:500;
		display:flex;
		align-items:center;
		justify-content:center;
		width:384px;
		height:56px;
		margin:0 0 24px;
		border-radius:5px;
		background:$color-cream;
		@include sp{
			font-size:14px;
			width:100%;
			height:35px;
			margin-bottom:8px;
		}
	}
	&--caption{
		font-size:14px;
		line-height:1.5;
		margin:24px auto 0;
		@include sp{
			font-size:12px;
		}
	}
}

.top_schedule{
	&--wrap{
		color:$color-text;
		background:#fff;
		border-radius:5px;
		padding:24px 24px 40px;
		width:493px;
		margin:0 auto 56px;
		@include sp{
			width:100%;
			padding:16px;
			margin-bottom:40px;
		}
	}
	&--sub{
		display:block;
		color:#fff;
		font-size:16px;
		line-height:1;
		font-weight:900;
		background:$color-green;
		border-radius:3px;
		margin:0 0 29px;
		padding:9px 0;
		@include sp{
			font-size:12px;
			padding:7px 0;
			margin-bottom:16px;
		}
	}
	&--ttl{
		text-align:left;
		position:relative;
		color:$color-green;
		font-size:32px;
		font-weight:900;
		padding-left:88px;
		margin:0 0 32px;
		@include sp{
			font-size:20px;
			padding-left:58px;
			font-feature-settings:'palt';
			margin-bottom:24px;
		}
	}
	&--icon{
		position:absolute;
		left:14px;
		top:0;
		width:48px;
		height:62px;
		@include sp{
			left:10px;
			width:32px;
			height:40px;
		}
	}
	&--caption{
		display:block;
		color:$color-text;
		font-size:20px;
		font-weight:500;
		margin-top:12px;
		@include sp{
			font-size:14px;
		}
	}
	&--heading{
		color:$color-orange;
		font-size:22px;
		font-weight:900;
		margin:0 0 16px;
		@include sp{
			font-size:18px;
			margin-bottom:11px;
		}
	}
	&--list{
		width:378px;
		margin:0 auto;
		border-top:1px solid $color-green;
    + .top_schedule--heading {
      margin-top: 16px;
    }
		@include sp{
			width:100%;
		}
	}
	&--item{
		color:$color-text;
		font-size:20px;
		font-weight:900;
		border-bottom:1px solid $color-green;
		padding:22px 16px;
		text-align: center;
		@include sp{
			font-size:16px;
			white-space:nowrap;
			padding:16px 0 16px 10px;
		}
	}
	&--count{
		color:$color-green;
		font-size:16px;
		font-weight:900;
		margin-right:24px;
		vertical-align:center;
		@include sp{
			font-size:14px;
			margin-right:10px;
		}
	}
  &--text {
    font-size: 14px;
    line-height: 1.5;
    color: #f00;
    text-align: center;
    margin-bottom: 8px;
    @include sp {
      font-size: 12px;
    }
  }
  &--note {
    font-size: 14px;
    line-height: 1.5;
    margin-top: 5px;
    @include sp {
      font-size: 12px;
      text-align: left;
    }
  }
}

.top_voice{
	&--ttl{
		font-size:22px;
		font-weight:900;
		margin:0 0 24px;
		@include sp{
			font-size:18px;
			margin-bottom:16px;
		}
	}
	&--wrap{
		color:$color-text;
		display:flex;
		justify-content:space-between;
		align-items:flex-start;
		width:827px;
		background:$color-cream;
		padding:32px;
		margin:0 auto 40px;
		border-radius:8px;
		box-shadow:0 3px 6px rgba(0,0,0,.16);
		@include sp{
			width:100%;
			display:block;
			padding:16px 16px 24px;
			margin-bottom:24px;
		}
	}
	&--part{
		width:350px;
		margin-right:32px;
		flex-shrink:0;
		@include sp{
			width:100%;
			margin:0 0 16px;
		}
	}
	&--sub{
		position:relative;
		font-size:20px;
		line-height:1.5;
		font-weight:900;
		text-align:left;
		padding-left:64px;
		margin:0 0 16px;
		@include sp{
			font-size:16px;
			line-height:1.4;
			padding-left:49px;
		}
		&:before{
			position:absolute;
			left:0;
			top:0;
			display:flex;
			justify-content:center;
			align-items:center;
			@include icon;
			content:$icon-try;
			color:#fff;
			font-size:36px;
			width:56px;
			height:56px;
			border-radius:30px;
			border:1px solid #fff;
			background:$color-lightgreen;
			@include sp{
				font-size:28px;
				width:40px;
				height:40px;
			}
		}
	}
	&--txt{
		display:block;
		font-size:14px;
		line-height:1.5;
		font-weight:500;
		@include sp{
			font-size:12px;
		}

	}
	&--list{
		width:100%;
		padding-left:64px;
		@include sp{
			padding-left:48px;
		}
	}
	&--item{
		position:relative;
		font-size:14px;
		font-weight:500;
		line-height:1.5;
		text-align:justify;
		line-break:strict;
		padding:16px;
		margin-bottom:24px;
		background:#fff;
		border-radius:10px;
		box-shadow:0 3px 6px rgba(0,0,0,.16);
		@include sp{
			font-size:12px;
			padding:8px;
			margin-bottom:16px;
			&:last-of-type{
				margin-bottom:0;
			}
		}
		&:before,&:after{
			position:absolute;
			@include icon;
		}
		&:before{
			content:$icon-tail;
			color:#fff;
			font-size:17px;
			left:-17px;
			top:14px;
			filter:drop-shadow(-6px 2px 6px rgba(0,0,0,.16));
			@include sp{
				font-size:12px;
				left:-12px;
			}
		}
		&:after{
			left:-64px;
			top:0;
			content:$icon-voice;
			color:$color-orange;
			font-size:40px;
			@include sp{
				font-size:32px;
				left:-48px;
			}
		}
    // #10397-#13 CMSだけど一番最初のアイコンは固定でトライアイコン
    &:first-child:after {
      content: '';
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_2893%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%202893%22%20transform%3D%22translate%28-46.793%20-341%29%22%3E%20%3Cg%20id%3D%22%E6%A5%95%E5%86%86%E5%BD%A2_17%22%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%2017%22%20transform%3D%22translate%2846.793%20341%29%22%20fill%3D%22%2300c649%22%20stroke%3D%22%23fff%22%20stroke-width%3D%221%22%3E%20%3Ccircle%20cx%3D%2220%22%20cy%3D%2220%22%20r%3D%2220%22%20stroke%3D%22none%22%2F%3E%20%3Ccircle%20cx%3D%2220%22%20cy%3D%2220%22%20r%3D%2219.5%22%20fill%3D%22none%22%2F%3E%20%3C%2Fg%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_1151%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%201151%22%20transform%3D%22translate%2853.535%20354.645%29%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_5029%22%20data-name%3D%22%E3%83%91%E3%82%B9%205029%22%20d%3D%22M-2966.854%2C877.942l5.087%2C3.344-1.629%2C2.477-3.458-2.274v5.278h-2.964V874.487h2.965Zm13.833-3.351h-7.9v2.964h7.9Zm-2.612%2C3.67h-5.64v2.964h5.64v.739a1.84%2C1.84%2C0%2C0%2C1-1.84%2C1.84h-3.449v2.965h3.449a4.8%2C4.8%2C0%2C0%2C0%2C4.8-4.8v-3.7Zm9.436-3.774v2.574a1.839%2C1.839%2C0%2C0%2C1-1.84%2C1.839h-3.8v2.964h3.371v4.894h2.964v-5.622a4.8%2C4.8%2C0%2C0%2C0%2C2.27-4.076v-2.574Z%22%20transform%3D%22translate%282969.818%20-874.487%29%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat;
      background-size: contain;
      width: 40px;
      height: 40px;
    }
	}
}
.top-target_list {
	max-width: 792px;
	margin: 0 auto;
	margin-top: 24px;
	@include sp {
		width: calc(100% + 16px);
		position: relative;
		left: -8px;
	}
	&--item {
		padding: 16px 24px 16px 60px;
		background-color: #fff;
		border: 2px solid #F99036;
		border-radius: 12px;
		font-weight: bold;
		font-size: 24px;
		line-height: 1.5;
		color: #333333;
		text-align: left;
		position: relative;
		&+& {
			margin-top: 12px;
		}
		&:before {
			content: '';
			position: absolute;
			background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228%22%20height%3D%2228%22%20viewBox%3D%220%200%2028%2028%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_2829%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%202829%22%20transform%3D%22translate%2823473%206501%29%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_1618%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%201618%22%20width%3D%2228%22%20height%3D%2228%22%20transform%3D%22translate%28-23473%20-6501%29%22%20fill%3D%22none%22%2F%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_2828%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%202828%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_1617%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%201617%22%20width%3D%2222%22%20height%3D%2222%22%20transform%3D%22translate%28-23470%20-6498%29%22%20fill%3D%22%23fff%22%2F%3E%20%3Cpath%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_1617_-_%E3%82%A2%E3%82%A6%E3%83%88%E3%83%A9%E3%82%A4%E3%83%B3%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%201617%20-%20%E3%82%A2%E3%82%A6%E3%83%88%E3%83%A9%E3%82%A4%E3%83%B3%22%20d%3D%22M2%2C2V20H20V2H2M0%2C0H22V22H0Z%22%20transform%3D%22translate%28-23470%20-6498%29%22%20fill%3D%22%23f99036%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_6265%22%20data-name%3D%22%E3%83%91%E3%82%B9%206265%22%20d%3D%22M-23460.1-6480.348l-.7-.622-5.836-5.226%2C1.336-1.49%2C5.141%2C4.6%2C12.2-11.825%2C1.391%2C1.436Z%22%20fill%3D%22%23f99036%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
			background-repeat: no-repeat;
			background-size: contain;
			width: 28px;
			height: 28px;
			left: 20px;
			top: calc(50% - 14px);
		}
		@include sp {
			font-size: 18px;
			padding: 12px 16px;
			padding-left: 43px;
			&:before {
				width: 24px;
				height: 24px;
				left: 16px;
				top: calc(50% - 12px);
			}
		}
	}
	&--item > sup {
		font-size: 70%;
		vertical-align: top;
		position: relative;
		top: -0.1em;
	}
	&--annotate {
		font-size: 12px;
		line-height: 1.5;
		color: #fff;
		text-align: left;
		margin-top: 12px;
	}
}